input[type="range"] {
  width: 100%;
  max-width: 75%;
  margin-top: 1.5rem;
}

.gauge-container {
  width: 100%;
  max-width: 75%;
  margin: 4rem auto;
  text-align: center;

  @media (max-width: 767px) {
    margin: 2.5rem auto;
  }
}

/* Track */
.custom-slider .rc-slider-rail {
  height: 15px;
  border-radius: 0px;
  background: linear-gradient(to right, #ff0000, #00ff00);

  @media (max-width: 576px) {
    height: 10px;
  }
}


/* Selected Track */
.custom-slider .rc-slider-track {
  background: transparent;
}

/* Count */
.custom-slider .rc-slider-mark {
  top: -23px !important;
  font-size: 1.25rem !important;
  font-weight: bold;
}

.custom-slider .rc-slider-mark-text {
  color: white;
  cursor: auto;

  @media (max-width: 767px) {
    font-size: 1.25rem;
  }

  @media (max-width: 576px) {
    font-size: 1rem;
  }
}

.custom-slider .scoreContainer {
  margin-top: 1.1rem;
}

/* Arrow */
.custom-slider .rc-slider-handle {
  /*  width: 0;
  height: 0;
  background-color: transparent;
  border-style: solid;
  border-width: 0;
  border-radius: 0;
  border-left-width: 11px;
  border-right-width: 11px;
  border-bottom-width: 22px;
  border-left-color: transparent;
  border-right-color: transparent;
  border-bottom-color: white;
  margin: 0;
  margin-left: -6px;
  margin-top: 22px;
  transform: rotate(360deg);
*/
}

.score-arrow {
  position: absolute;
  text-align: center;
  pointer-events: none;
  width: max-content;
}

.arrow-up {
  width: 0;
  height: 0;
  border-left: 11px solid transparent;
  border-right: 11px solid transparent;
  border-bottom: 22px solid #ffffff;
  margin: 0 auto;

  @media (max-width: 991px) {
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-bottom: 18px solid #ffffff;
  }

  @media (max-width: 767px) {
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-bottom: 18px solid #ffffff;
  }
}

.score-text {
  background: transparent;
  color: white;
  padding: 2px 5px;
  border-radius: 3px;
  font-size: 1.2rem;
  font-weight: bold;
  display: inline-block;
  margin-top: 2px;

  @media (max-width: 991px) {
    padding: 2px 5px;
    border-radius: 3px;
    font-size: 1rem;
  }
}

.custom-slider .rc-slider-handle {
  border: none;
  background-color: transparent;
  height: 30px;
  width: 10px;
  margin-top: -5px;
  /* Adjust if needed */
  z-index: 2;
}

.gauge-label {
  margin-top: 10px;
  font-size: 16px;
}

.custom-slider .rc-slider-dot {
  display: none;
}