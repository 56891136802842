html {
  font-size: 16px;
  width: 100%;
  height: 100dvh;
}
body {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  color: #212529;
  font-family: Cabin, sans-serif !important;
  font-size: 0.9rem;
  line-height: normal;
  padding: 0;
  margin: 0;
  touch-action: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}
#root {
  width: 100%;
  height: 100%;
}

*, *::before, *::after {
  box-sizing: revert !important;
}


